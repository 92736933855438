
import moment from 'moment';
import { defineComponent, onMounted, PropType, reactive, ref, toRefs, watch } from 'vue'
import { ContentLoader } from 'vue-content-loader';
import ApexChart from "apexcharts";

interface dadoPacote {
    pacote: string;
    valor: number
}

interface dadosPacotesInfo {
    dados: Array<dadoPacote>;
	percentualPacotes: string;
    somaPacotes: number;
    revisoes: number;
}

export default defineComponent({
    props: {
        dadosPacotes: {
            type: Object as PropType<dadosPacotesInfo>
        },
        loading: {
            type: Boolean
        },
    },
    components: {
        ContentLoader
    },
	setup(props) {
		const chartOptions:any = ref(geraOptions());
		const pacotes = reactive({
            semPacote: 0,
            percentualSemPacote: "0",
            recomendado: 0,
            percentualRecomendado: "0",
            intermediario: 0,
            percentualIntermediario: "0",
            basico: 0,
            percentualBasico: "0"
        });
        let chartInstance;
        const percentualTotalPacotes = ref("0,00");

        onMounted(() => {
            const options = geraOptions();
            chartInstance = new ApexChart(document.querySelector("#graficoPacotes"), {
                ...options,
                series: [0, 0, 0, 0],
            });
            chartInstance.render();
        });

        watch(() => props.dadosPacotes, (value: any) => {
            if(props.dadosPacotes){
                const { dados, somaPacotes, revisoes } = props.dadosPacotes;
                pacotes.semPacote = dados[0].valor ? Number(dados[0].valor) : 0;
                pacotes.recomendado = dados[1].valor  ? Number(dados[1].valor) : 0;
                pacotes.intermediario = dados[2].valor  ? Number(dados[2].valor) : 0;
                pacotes.basico = dados[3].valor  ? Number(dados[3].valor) : 0;

                const auxSeries = [pacotes.recomendado, pacotes.intermediario, pacotes.basico, pacotes.semPacote];

                pacotes.percentualSemPacote = pacotes.semPacote && revisoes ? ((pacotes.semPacote / revisoes) * 100).toFixed(2) : "0";
                pacotes.percentualRecomendado = pacotes.recomendado && revisoes ? ((pacotes.recomendado / revisoes) * 100).toFixed(2) : "0";
                pacotes.percentualIntermediario = pacotes.intermediario && revisoes ? ((pacotes.intermediario / revisoes) * 100).toFixed(2) : "0";
                pacotes.percentualBasico = pacotes.basico && revisoes ? ((pacotes.basico / revisoes) * 100).toFixed(2) : "0";

                let auxPercentualPacotes: string | number = somaPacotes && revisoes ? (somaPacotes / revisoes) * 100 : 0;
                auxPercentualPacotes = auxPercentualPacotes.toFixed(2);
                percentualTotalPacotes.value = auxPercentualPacotes;
                chartInstance.updateSeries(auxSeries);
                
                resizeWithTime();
            }
        }, { deep: true });

        function geraOptions() {
            const newOptions = {
                dataLabels: {
                enabled: false,
                },
                chart: {
                width: '100%',
                height: '230',
                type: 'donut',
                options: "chart",
                series: "series",
                offsetY: -20
                },
                stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                colors: undefined,
                width: 2,
                dashArray: 0,      
                },
                plotOptions: {
                pie: {
                    customScale: 0.95,
                    donut: {
                    size: '80%'
                    }
                }
                },
                labels: ['Pacote Recomendado', 'Pacote Intermediário', 'Pacote Básico', 'Sem Pacote'],
                colors: ['var(--cor_indicadores_1)', 'var(--cor_indicadores_2)', 'var(--cor_indicadores_3)', 'var(--cor_indicadores_4)'],
                fill: {
                colors:  ['var(--cor_indicadores_1)', 'var(--cor_indicadores_2)','var(--cor_indicadores_3)', 'var(--cor_indicadores_4)']
                },
                legend: {
                show:false,
                },
            }

            return newOptions;
        };

        function resizeWithTime(time = 100){
            setTimeout(() => window.dispatchEvent(new Event('resize')), time);
        };

		return { chartOptions, percentualTotalPacotes, ...toRefs(pacotes) }
	},
})
