
import moment from 'moment';
import { defineComponent, onMounted, PropType, reactive, ref, toRefs, watch } from 'vue'
import { ContentLoader } from 'vue-content-loader';
import ApexChart from "apexcharts";
import distribuidorConfig from "@/core/config/DistribuidorConfig";

interface IDadoTicketMedio {
    codConcessionaria: string;
    nomeFantasia: string;
    nprodutos: number;
    nrevisoes: number;
    revisoes: string;
    percentualTicketMedio: string;
    percentualMetaTicketMedio: string;
    corPercentual: string;
    valor: string;
    valortotal: string;
    metaticketmedio: string;
}

export default defineComponent({
    props: {
        dadosTicketMedio: {
            type: Array as PropType<Array<IDadoTicketMedio>>
        },
        loading: {
            type: Boolean
        },
    },
    components: {
        ContentLoader
    },
	setup(props) {
        let { distribuidor } = distribuidorConfig[0];
        distribuidor = distribuidor ? `${distribuidor.substring(0, 1).toUpperCase()}${distribuidor.substring(1).toLowerCase()}` : "";

        return { distribuidor }
	},
})
